
import { defineComponent, reactive, ref, PropType, toRefs } from "vue";
import LoginStore from "@/store/modules/login";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";
import { useRouter, useRoute } from "vue-router";
import { login1 } from "@/api/login1";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import { encStr } from '@/utils/encryption'

export default defineComponent({
  name: "",
  setup() {
    const loginStore = getModule(LoginStore, store);
    let checkAge = async (rule: RuleObject, value: number) => {
      const passwordreg = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,20}/
      if (!value) {
        return Promise.reject('请输入密码');
      } else {
        if (!passwordreg.test(value)) {
          // return true
          return Promise.reject('密码为8-20位,且同时包含数字、大写字母、小写字母、特殊符号');
        } else {
          return true
        }
      }
    };
    const data = reactive({
      loginForm: {
        phone: "",
        password: "",
        checkLogin: false,
      },
      loginRules: {
        phone: [{ required: true, message: "请输入手机号" }],
        password: [{ validator: checkAge, trigger: 'change' }],
      },
      layout: {
        labelCol: { span: 0 },
        wrapperCol: { span: 24 },
      },
      loading: false,
    });
    const loginRefs = ref();
    const router = useRouter();
    const handleLogin = () => {
      loginRefs.value.validate().then(() => {
        data.loading = true;
        // login1(data.loginForm as AxiosRequestConfig)
        loginStore
          .login(data.loginForm as AxiosRequestConfig)
          .then(() => {
            data.loading = false;
            cookies.set("phone",encStr(data.loginForm.phone))
            cookies.set("password",encStr(data.loginForm.password))
            router.push("/home");
          })
          .catch(() => {
            data.loading = false;
          });
      });
    };
    return {
      ...toRefs(data),
      handleLogin,
      loginRefs,
    };
  },
});
